<!-- TODO: переименовать в BaseSnackbar -->
<template>
  <v-app data-snackbar-notification>
    <!-- TODO: сбрасывать таймер при смене текста -->
    <v-snackbar
      v-model="isActive"
      v-touch="{
        down: closeSnackbar,
      }"
      v-bind="{ ...snackbarSettings }"
      class="snackbar-notification"
      :class="{ 'snackbar-notification_dialog': upper }"
      :timeout="snackbarTimeout"
      color="uiKitText"
    >
      <span
        class="ui-text ui-text_body-2"
      >
        {{ message }}
      </span>

      <template
        v-if="actionText"
        #action="{ attrs }"
      >
        <v-btn
          v-bind="attrs"
          color="uiKitButtonTextSnackbars"
          class="fix-medotvet"
          text
          @click="handleAction"
        >
          {{ actionText }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { dispatchCustomEventGlobally } from 'utils'
import { TIMEOUT, EVENTS } from './constants'

export default {
  name: 'SnackbarNotification2',
  data() {
    return {
      isActive: false,
      message: '',
      actionText: '',
      upper: false,
      snackbarSettings: {},
      handleAction: () => {},
    }
  },
  computed: {
    snackbarTimeout() {
      return this.actionText ? TIMEOUT.withAction : TIMEOUT.withoutAction
    },
  },
  created() {
    window.addEventListener(EVENTS.open, e => this.openSnackbar(e.detail))
    window.addEventListener(EVENTS.close, e => this.closeSnackbar(e.detail))
    /**
     * Компонент монтируется в асинхронной функции, поэтому добавляется обработчик,
     * который сигнализирует о создании компонента.
     * */
    window.addEventListener(EVENTS.checkCreated, () => dispatchCustomEventGlobally(EVENTS.created))
    dispatchCustomEventGlobally(EVENTS.created)
  },
  methods: {
    async openSnackbar({
      message,
      upper = false,
      actionText,
      snackbarSettings,
      handleAction,
    }) {
      /**
             * Если snackbar уже был открыт, то сначала закрываем его, и после анимации закрытия открываем
             * (это добавляет эффект обновления snackbar при множественном открытии)
             * */
      if (this.isActive) {
        await this.closeSnackbar()
      }

      this.isActive = true
      this.message = message
      this.actionText = actionText
      this.upper = upper
      this.snackbarSettings = snackbarSettings
      this.handleAction = handleAction
    },
    async closeSnackbar() {
      this.isActive = false

      await this.waitCloseAnimation()
      this.resetSnackbar()
    },
    resetSnackbar() {
      Object.assign(this.$data, this.$options.data.apply(this))
    },
    waitCloseAnimation() {
      return new Promise(resolve => {
        setTimeout(resolve, 150)
      })
    },
  },
}
</script>

<style lang="scss">
@import '~www/themes/doctors/common/mixins';

#app[data-snackbar-notification] {
  @include v-app-reset;

  .snackbar-notification {
    inset: 0;
    height: initial;

    // выше BaseDialog и SideMenu
    z-index: 10003;

    .v-snack__wrapper {
      min-width: 304px;
      width: auto;
    }

    &.snackbar-notification_dialog .v-snack__wrapper {
      bottom: 56px;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/vuetify-custom-reset';
@include vuetify-custom-reset;

// на МедОтвет jq стили перебивают vuetify
.fix-medotvet {
  text-shadow: none;
  background-color: initial;

  &:hover,
  &:focus {
    text-shadow: none;
    background-color: initial;
  }
}
</style>
