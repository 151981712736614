// @ts-ignore
import Cookie from 'js-cookie'

function showBannerCookie() {
  try {
    const bannerNode = document.querySelector('.cookie-banner')

    if (!bannerNode) {
      return
    }

    if (!navigator.cookieEnabled) {
      bannerNode.classList.remove('d-none')

      return
    }

    const hasCookie = Cookie.get('is-cookie-banner-visible')

    if (!hasCookie) {
      bannerNode.classList.remove('d-none')
    }
  } catch { /* Empty */ }
}

function handleClick({ target }: MouseEvent) {
  try {
    if (!navigator.cookieEnabled) {
      return
    }

    if (!(target instanceof HTMLElement)) {
      return
    }

    const button = target.closest('.cookie-banner-button')

    if (!button) {
      return
    }

    const bannerNode = button.closest('.cookie-banner')

    if (bannerNode) {
      bannerNode.classList.add('d-none')
    }

    Cookie.set('is-cookie-banner-visible', true, {
      expires: 365,
      sameSite: 'lax',
    })
  } catch { /* Empty */ }
}

document.addEventListener('DOMContentLoaded', showBannerCookie)
document.addEventListener('click', handleClick)
