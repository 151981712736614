/**
 * Важно вызывать Sentry.init только один раз, т.к. повторная
 * инициализация Sentry приводит к повторной отправке одной и той же ошибки.
 */

import * as Sentry from '@sentry/vue' // eslint-disable-line import/no-extraneous-dependencies
import Vue from 'vue'
import {
  isProd,
  isStage,
  isBrowserSupported,
} from 'utils'

function initSentry() {
  if (!isProd && !isStage) {
    return
  }

  const { userId } = document.body.dataset

  const options = {
    Vue,
    id: userId,
    dsn: isProd
      ? 'https://dd07421763e442e9b7b2689f7b1748bb@sentry.medrocket.ru/9'
      : 'https://9a352316fb7d40c5b6dacbdd52e9c435@sentry.prodoctorov.com/28',
    normalizeDepth: 10,
    autoSessionTracking: false,
    release: process.env.CI_COMMIT_SHA,
    ignoreErrors: [
      /www\/js\/libs/gmi, // Игнорирование всех ошибок библиотек(legacy на pd)
      /network\s+error/gmi, // Игнорируем ошибки сети
      /resizeObserver/gmi, // Игнорируем ошибки инстансов 'ResizeObserver'. Они не используются на pd, но сыпятся из библиотек
      /the string did not match the expected pattern/gmi, // Игнорируем ошибки Safari
      /Non-Error exception captured/gmi, // Игнорируем ошибки 'UnhandledRejection'. В интернете писали, что удалось воспроизвести с помощью проверки почтового клиента ссылку на безопасную в письме
      /Non-Error promise rejection captured/gmi, // Игнорируем ошибки 'UnhandledRejection'. В интернете писали, что удалось воспроизвести с помощью проверки почтового клиента ссылку на безопасную в письме
      /Can not detect viewport width/gmi, // Игнорирование ошибки owl slider
      /Request aborted/gmi, // Игнорирование всех прерываний запросов с помощью ajax
      /timeout of 0ms exceeded/gmi, // Игнорируем ошибки таймаута запроса axios(если нет интернета, а время ожидания истекло)
      /\/?\w:\/+(\w+\/)*/gmi, // Игнорирование всех локальных запусков(C://Users, F:// и тд)
      /AbortError/gmi, // Игнорирование ошибок получения ресурсов, когда пользователь сам разрывает соединение
      /AggregateError/gmi, // Игнорирование ошибок агрегации нескольких предупреждений, в проекте такое не используется, а значит ошибки падают из библиотек
      /Load\s+failed/gmi, // Игнорирование ошибок загрузки ресурсов(на iOS)
      /Attempted to assign to readonly property/gmi, // Игнорирование ошибки, которая возникает на iOS во время использования пакета 'body-scroll-lock'
      /undefined is not an object \(evaluating 'window\.webkit\.messageHandlers/gmi, // Ошибка сыпется из 'node-modules'
      /__ybHasBeenPasswordField/gmi, // Ошибка, которая возникает в стороннем коде(во всяком случе bread crumbs на это указывают)
      /MtPopUpList\s+is\s+not\s+defined/gmi, // Ошибка возникает во время парсинга PD
      /timeout exceeded/gmi, // Ошибка возникает только на iOS у многих пользователей. Причина возникновения - неизвестна. Возможно ошибка сети
      /Failed to read the 'cookie' property from 'Document'./gmi, // Ошибка может возникнуть во время встраивания ресурса в iframe в режиме песочницы
      /Failed to fetch/gmi, // Ошибка может возникнуть в случае проблем с сетью
      /The quota has been exceeded/gmi, // Ошибка может возникнуть в случае переполнения localStorage пользователя
      /NS_ERROR_FAILURE/gmi, // Ошибка Firefox - бесполезна
    ],
    beforeSend(event) {
      try {
        const excludeModuleList = [
          'runtime.js',
          'node-modules.js',
        ]

        const srcScriptSSRList = [...document.querySelectorAll('script[data-qa="ssr-scripts"]')].map(({ src }) => src)
        const eventChunkList = event?.exception?.values.map?.(
          ({ stacktrace: { frames } }) => frames[frames.length - 1].filename,
        ) || []

        if (!eventChunkList.length) {
          return
        }

        const isAllowedEvent = srcScriptSSRList.some(src => (
          eventChunkList.includes(src) && !excludeModuleList.some(s => src.includes(s))
        ))

        if (isAllowedEvent) {
          return event
        }
            } catch {} // eslint-disable-line
    },
  }

  Sentry.init(options)
}

try {
  const isEnabledSentry = JSON.parse(
    typeof window?.isEnabledSentry === 'string'
      ? window.isEnabledSentry.toLowerCase() // на случай если из шаблона удалят фильтр 'yesno' и не было поломок
      : window?.isEnabledSentry,
  )

  if (isEnabledSentry && isBrowserSupported()) {
    initSentry()
  }
} catch {} // eslint-disable-line
