var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("UserAuthorization", {
    ref: "user-authorization",
    attrs: {
      "is-render-in-modal": "",
      "is-phone-readonly": !!_vm.userData.phone,
      "is-visible":
        !_vm.userData.isLogged || !_vm.userData.isPhoneConfirmedByCall,
      "init-phone": _vm.userData.phone,
      "is-authorization-by-call": _vm.commonData.useCallConfirmation,
      "is-needed-confirm-by-call": !_vm.userData.isPhoneConfirmedByCall,
      "is-country-authorization": "",
    },
    on: {
      "authorization:confirmed": _vm.handlePhoneConfirmed,
      "authorization:just-confirmed": _vm.handlePhoneJustConfirmed,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }