export const TIMEOUT = {
  withAction: 7000,
  withoutAction: 4000,
}

export const ERROR = {
  message: 'Произошла ошибка. Попробуйте обновить страницу',
  actionText: 'Обновить',
  handleAction: () => {
    window.location.reload()
  },
}

export const EVENTS = {
  open: 'snackbar:open',
  close: 'snackbar:close',
  created: 'snackbar:created',
  checkCreated: 'snackbar:check-created',
}
