import axios from 'axios'
import { axiosClient } from 'utils'

let axiosCancelTokenSource

/**
 * @description Производит запрос для подтверждения кода из смс или по 4м последним цифрам входящего звонка.
 *
 * @param { Object } options
 * @param { String } options.phone - // 79189999999
 * @param { String } options.code - // 1234
 * @param { String } options.serviceId - // 3fa85f64-5717-4562-b3fc-2c963f66afa6
 * @param { Boolean } options.mobileApp
 *
 * @returns { Promise }
 */

function requestCheckCodeForUser({
  phone = '',
  code = '',
  serviceId = '',
  mobileApp = false,
} = {}) {
  const clientIdGetParam = `?client_id=${window.MEDTOCHKA_CLIENT_ID}`

  axiosCancelTokenSource = axios.CancelToken.source()

  return axiosClient.post(`${window.MEDTOCHKA_URL}/services/sso/confirmation/confirm/${clientIdGetParam}`, {
    phone,
    code,
    service_id: serviceId || null,
    mobile_app: mobileApp,
  }, {
    withCredentials: true,
    cancelToken: axiosCancelTokenSource.token,
  })
}

function cancelRequestCheckCodeForUser() {
  if (axiosCancelTokenSource?.cancel) {
    axiosCancelTokenSource.cancel()
  }
}

export {
  requestCheckCodeForUser,
  cancelRequestCheckCodeForUser,
}
