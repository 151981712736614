export const REGEX = {
  ru: /[а-яА-ЯёЁ]/,
  latinEWithDiaeresis: /[ëË]/, // Латинская строчная буква e(E) с диэризисом. Символы \00EB и \00CB
  email: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@(?:[a-zA-Z0-9-]+\.)+(?:[a-zA-Z0-9-]{2,})$/,
  fio: /^\s*[а-яА-ЯёЁ]+([- '][а-яА-ЯёЁ]+)*\s*$/,
  trimNotLetter: /^[^а-яА-ЯёЁ]+|[^Аа-яА-ЯёЁ]+$/,
  phone: /^((\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/,
  invalidPassword: /[^a-zA-Z0-9]/,
  universalPhone: /^\d{7,15}$/,
}
