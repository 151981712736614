import getNamespace from 'modules/ExpansionPanels/functions/getNamespace'

const namespace = getNamespace()

function getUpgradeHeadTemplate({
  innerHTML = '',
  hasArrow,
  isRightArrow,
  hasPaddingArrow,
}) {
  const buttonClasses = [namespace.button]

  if (hasArrow) {
    buttonClasses.push(namespace.buttonHasArrow)

    if (!hasPaddingArrow) {
      buttonClasses.push(namespace.buttonArrowPadding)
    }

    if (isRightArrow) {
      buttonClasses.push(namespace.buttonRightArrow)
    }
  }

  return `<button
      type="button"
      class="${buttonClasses.join(' ')}"
      aria-expanded="false"
  >${innerHTML}</button>`
}

export default getUpgradeHeadTemplate
