import axios from 'axios'
import { axiosClient } from 'utils'

let axiosCancelTokenSource

/**
 * @description Запрос на завершение WS для подтверждения номера по звонку
 *
 * @param { Object } options
 * @param { String } options.phone
 * @param { String } options.uuid
 * @param { String } options.source - 'ws'|'timer' (сокет/таймер) - для логов, место откуда идёт запрос
 *
 * @returns { Promise }
 */

function requestCallConfirmationFinish({ phone, uuid, source }) {
  const clientIdGetParam = `?client_id=${window.MEDTOCHKA_CLIENT_ID}`

  axiosCancelTokenSource = axios.CancelToken.source()

  return axiosClient.post(
    `${window.MEDTOCHKA_URL}/services/sso/calls/finish_request/${clientIdGetParam}`,
    {
      phone,
      uuid,
    },
    {
      withCredentials: true,
      params: {
        source,
        time: Date.now(), // для логов на бэке
      },
      cancelToken: axiosCancelTokenSource.token,
    },
  )
}

function cancelRequestCallConfirmationFinish() {
  if (axiosCancelTokenSource?.cancel) {
    axiosCancelTokenSource.cancel()
  }
}

export {
  requestCallConfirmationFinish,
  cancelRequestCallConfirmationFinish,
}
