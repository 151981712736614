var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "review-get-user-email-success",
      class: { "review-get-user-email-success_mobile": _vm.isMobile },
      attrs: { "data-qa": "review_get_user_email_success" },
    },
    [
      _c(
        "div",
        { staticClass: "review-get-user-email-success__inner text-center" },
        [
          _c(
            "div",
            { class: { "my-auto": _vm.isMobile } },
            [
              _c("VImg", {
                staticClass: "flex-grow-0 mx-auto mb-4",
                attrs: {
                  contain: "",
                  width: "200",
                  height: "200",
                  "data-qa": "review_get_user_email_success_img",
                  alt: "Палец вверх",
                  src: "/static/_v1/pd/pictures/ui-kit/like.svg",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "ui-text ui-kit-color-text mb-2",
                  class: {
                    "ui-text_h6": _vm.isMobile,
                    "ui-text_h5": !_vm.isMobile,
                  },
                  attrs: { "data-qa": "review_get_user_email_success_title" },
                },
                [
                  _vm.commonData.isReviewSourcePrs
                    ? [
                        _vm._v("\n          Принято. Сообщим о проверке"),
                        _c("br"),
                        _vm._v("\n          отзыва и пришлём промокод"),
                        _c("br"),
                        _vm._v("\n          на почту\n        "),
                      ]
                    : [
                        _vm._v("\n          Принято. Сообщим о "),
                        _c("br"),
                        _vm._v(
                          "\n          проверке отзыва по почте\n        "
                        ),
                      ],
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "ui-text ui-text_body-1 ui-kit-color-text mb-4",
                  attrs: {
                    "data-qa": "review_get_user_email_success_description",
                  },
                },
                [_vm._v("\n        " + _vm._s(_vm.userData.email) + "\n      ")]
              ),
              _vm._v(" "),
              !_vm.commonData.isPdMobile
                ? _c(
                    "div",
                    {
                      staticClass:
                        "review-get-user-email-success__banner ui-text ui-text_body-1 mb-4",
                      attrs: {
                        "data-qa": "review_get_user_email_success_banner",
                      },
                    },
                    [
                      _c("p", { staticClass: "mb-2" }, [
                        _vm._v(
                          "\n          Если вы не были в этой клинике\n          и оставили отзыв по заказу клиники или\n          врача, сообщите нам об этом и получите вознаграждение\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      !_vm.isYDoc
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: "/info/rate-fraud-free/",
                                target: "_blank",
                              },
                            },
                            [_vm._v("Подробнее")]
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isMobile
            ? _c(
                "VBtn",
                {
                  staticClass: "mb-4",
                  attrs: {
                    depressed: "",
                    color: "primary",
                    target: "_blank",
                    "data-qa": "review_get_user_email_success_mtlink",
                    href: _vm.commonData.medtochkaUrlOnReview,
                  },
                },
                [_vm._v("\n      Посмотреть отзыв\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isMobile
            ? _c("BottomPanel", {
                attrs: { fixed: "", "no-height": "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "container",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "px-2 pt-2 pb-6" },
                            [
                              _c(
                                "VBtn",
                                {
                                  attrs: {
                                    block: "",
                                    depressed: "",
                                    color: "primary",
                                    target: "_blank",
                                    "data-qa":
                                      "review_get_user_email_success_mtlink",
                                    href: _vm.commonData.medtochkaUrlOnReview,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            Посмотреть отзыв\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1400027756
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }