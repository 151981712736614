import axios from 'axios'
import AuthService from 'services/AuthService'
import {
  showCommonErrorDialog,
  dispatchCustomEventGlobally,
  createDevNotice,
  getOnlyNumbers,
} from 'utils'
import { showCommonErrorMessage } from 'components/mobile/PhoneConfirmation/functions'
import { EVENTS as DIALOG_EVENTS } from 'components/common/core/BaseDialog/constants'
import {
  requestCheckCodeForUser,
  requestCheckCodeForStaff,
  requestCheckCodeForUserNoRegistration,
  cancelRequestCheckCodeForUser,
  cancelRequestCheckCodeForStaff,
  cancelRequestCheckCodeForUserNoRegistration,
} from 'components/common/UserAuthorization/api'
import {
  AUTH_ERRORS,
  CONFIRMATION_METHODS,
  DIALOG_ERROR_NETWORK_CONFIG,
} from 'components/common/UserAuthorization/constants'

/**
 * В данном миксине происходит обработка всех запросов на
 * подтверждение кода (из СМС или 4 последние цифры номера входящего звонка).
 * */

const authByCallOrSmsCheckCodeMixin = {
  props: {
    phone: {
      type: String,
      default: '',
    },
    serviceId: {
      type: String,
      default: '',
    },
    isNoRegistration: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    requestCheckCodeData: {
      code: '',
      errorMessage: '',
      isLoading: false,
    },
  }),
  computed: {
    countryCodeLength() {
      return getOnlyNumbers(window.COUNTRY_DATA.phone.code).length
    },
    confirmationMethod() {
      if (this.confirmationMethods.isVk && !this.requestGetCodeData.isVkIgnored) {
        return CONFIRMATION_METHODS.vk
      }

      if (this.confirmationMethods.isCall) {
        return CONFIRMATION_METHODS.call
      }

      return CONFIRMATION_METHODS.sms
    },
  },
  watch: {
    'requestCheckCodeData.code': {
      handler() {
        this.requestCheckCodeData.errorMessage = ''
      },
    },
  },
  methods: {
    reset() {
      this.requestCheckCodeData.code = ''

      if (this.requestCheckCodeData.isLoading) {
        this.cancelRequests()
      }
    },
    cancelRequests() {
      cancelRequestCheckCodeForUser()
      cancelRequestCheckCodeForStaff()
      cancelRequestCheckCodeForUserNoRegistration()
    },

    /**
         * Controller
         * */
    async handleCheckCode() {
      if (this.isNoRegistration) {
        await this.onCheckCodeForUserNoRegistration()
        return
      }

      if (window.USER.isStaff) {
        await this.onCheckCodeForStaff()
        return
      }

      await this.onCheckCodeForUser()
    },

    /**
         * NoRegistration
         * */
    async onCheckCodeForUserNoRegistration() {
      try {
        this.requestCheckCodeData.isLoading = true

        const phone = window.FEATURE_FLAGS.pd_ydoc_phone_validation
          ? getOnlyNumbers(this.phone).slice(this.countryCodeLength)
          : this.phone.slice(1)

        const response = await requestCheckCodeForUserNoRegistration({
          phone,
          code: this.requestCheckCodeData.code,
        })

        if (response.data === 'OK') {
          this.handleSuccessCheckCodeForUserNoRegistration()
        } else {
          this.handleErrorCheckCodeForUserNoRegistration({ response })
        }

        this.requestCheckCodeData.isLoading = false
      } catch (e) {
        this.requestCheckCodeData.isLoading = false

        /**
                 * Если запрос отменил пользователь (cancelRequestCheckCodeForUserNoRegistration),
                 * то не обрабатываем это как ошибку
                 * */
        if (axios.isCancel(e)) {
          return
        }

        this.handleErrorCheckCodeForUserNoRegistration(e)
      }
    },
    handleSuccessCheckCodeForUserNoRegistration() {
      this.$emit('authorization-check-code:success', { phone: this.phone })
    },
    handleErrorCheckCodeForUserNoRegistration(errorEvent) {
      const { response, message } = errorEvent || {}
      const errorCode = response?.data

      if (AUTH_ERRORS[errorCode]) {
        this.requestCheckCodeData.errorMessage = AUTH_ERRORS[errorCode]
        return
      }

      if (!window.navigator.onLine || message === 'Network Error') {
        dispatchCustomEventGlobally(DIALOG_EVENTS.open, DIALOG_ERROR_NETWORK_CONFIG)
        return
      }

      this.requestCheckCodeData.errorMessage = message

      showCommonErrorDialog(errorEvent, {
        sentryModule: 'authByCallOrSmsCheckCodeMixin.js',
        sentryMethod: 'handleErrorCheckCodeForUserNoRegistration',
      })
    },

    /**
         * Staff
         * */
    async onCheckCodeForStaff() {
      try {
        this.requestCheckCodeData.isLoading = true

        const phoneWithoutCountryCode = window.FEATURE_FLAGS.pd_ydoc_phone_validation
          ? getOnlyNumbers(this.phone).slice(this.countryCodeLength)
          : this.phone.slice(1)

        await requestCheckCodeForStaff({
          phone: phoneWithoutCountryCode,
          code: this.requestCheckCodeData.code,
        })

        this.handleSuccessCheckCodeForStaff()

        this.requestCheckCodeData.isLoading = false
      } catch (e) {
        this.requestCheckCodeData.isLoading = false

        /**
                 * Если запрос отменил пользователь (cancelRequestCheckCodeForStaff),
                 * то не обрабатываем это как ошибку
                 * */
        if (axios.isCancel(e)) {
          return
        }

        this.handleErrorCheckCodeForStaff(e)
      }
    },
    handleSuccessCheckCodeForStaff() {
      this.$emit('authorization-check-code:success', { phone: this.phone })
    },
    handleErrorCheckCodeForStaff(errorEvent) {
      const { response, message } = errorEvent || {}
      const errorData = response?.data
      const errorCode = errorData?.detail

      if (AUTH_ERRORS[errorCode]) {
        this.requestCheckCodeData.errorMessage = AUTH_ERRORS[errorCode]
        return
      }

      if (!window.navigator.onLine || message === 'Network Error') {
        dispatchCustomEventGlobally(DIALOG_EVENTS.open, DIALOG_ERROR_NETWORK_CONFIG)
        return
      }

      this.requestCheckCodeData.errorMessage = message

      showCommonErrorDialog(errorEvent, {
        sentryModule: 'authByCallOrSmsCheckCodeMixin.js',
        sentryMethod: 'handleErrorCheckCodeForStaff',
      })
    },

    /**
         * User
         * */
    async onCheckCodeForUser() {
      try {
        this.requestCheckCodeData.isLoading = true

        const response = await requestCheckCodeForUser({
          phone: this.phone,
          code: this.requestCheckCodeData.code,
          serviceId: this.serviceId,
        })

        await this.handleSuccessCheckCodeForUser(response)

        this.requestCheckCodeData.isLoading = false
      } catch (e) {
        this.requestCheckCodeData.isLoading = false

        /**
                 * Если запрос отменил пользователь (cancelRequestCheckCodeForUser),
                 * то не обрабатываем это как ошибку
                 * */
        if (axios.isCancel(e)) {
          return
        }

        this.handleErrorCheckCodeForUser(e)
      }
    },
    async handleSuccessCheckCodeForUser(response) {
      try {
        await AuthService.login({
          authCode: response.data.authorization_code,
        })

        this.$emit('authorization-check-code:success', { phone: this.phone })
      } catch (e) {
        this.requestCheckCodeData.isLoading = false

        if (!window.navigator.onLine || e.message === 'Network Error') {
          dispatchCustomEventGlobally(DIALOG_EVENTS.open, {
            maxWidth: 304,
            persistent: true,
            noClickAnimation: true,
            title: 'Нет интернета',
            text: 'Проверьте соединение и обновите страницу.',
            confirmText: 'Обновить',
            beforeConfirm: () => window.location.reload(),
          })

          return
        }

        createDevNotice({
          module: 'authByCallOrSmsCheckCodeMixin.js',
          method: 'handleSuccessCheckCodeForUser',
          description: `Что-то пошло не так. Описание ошибки: ${JSON.stringify(e?.response)}`,
        })

        dispatchCustomEventGlobally(DIALOG_EVENTS.open, {
          maxWidth: 304,
          persistent: true,
          noClickAnimation: true,
          title: 'Что-то пошло не так',
          text: 'Попробуйте немного подождать и обновите страницу.',
          confirmText: 'Обновить',
          beforeConfirm: () => window.location.reload(),
        })
      }
    },
    handleErrorCheckCodeForUser(errorEvent) {
      const { response, message } = errorEvent || {}
      const errorData = response?.data
      const errorCode = errorData?.code
      const errorStatus = response?.status

      if (AUTH_ERRORS[errorCode]) {
        this.requestCheckCodeData.errorMessage = errorCode === 'error_code'
          ? AUTH_ERRORS[errorCode][this.confirmationMethod]
          : AUTH_ERRORS[errorCode]
        return
      }

      if (errorStatus === 404) {
        this.requestCheckCodeData.errorMessage = AUTH_ERRORS.obsolete[this.confirmationMethod]
        return
      }

      if (errorStatus === 403) {
        showCommonErrorMessage()
        return
      }

      if (!window.navigator.onLine || message === 'Network Error') {
        dispatchCustomEventGlobally(DIALOG_EVENTS.open, DIALOG_ERROR_NETWORK_CONFIG)
        return
      }

      if (!response) {
        showCommonErrorMessage()
        return
      }

      showCommonErrorDialog(errorEvent, {
        sentryModule: 'authByCallOrSmsCheckCodeMixin.js',
        sentryMethod: 'handleErrorCheckCodeForUser',
      })
    },
  },
}

export default authByCallOrSmsCheckCodeMixin
