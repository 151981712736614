var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VApp",
    { attrs: { "data-v-app-header": "" } },
    [
      _c("transition", { attrs: { name: "fade-transition" } }, [
        _c(
          "div",
          {
            staticClass: "search-dialog",
            attrs: { "data-qa": "the_header_search_dialog" },
          },
          [
            _c("VCard", { staticClass: "search-dialog__card" }, [
              _c(
                "div",
                { staticClass: "search-dialog__input-container" },
                [
                  _c("SearchSite", {
                    staticClass: "search-dialog__input px-2 py-3",
                    attrs: {
                      "data-qa": "the_header_search_site",
                      action: _vm.search.action,
                      "ajax-action": _vm.search.ajaxAction,
                      location: _vm.search.location,
                      placeholder: _vm.search.placeholder,
                      "preview-text": _vm.search.previewText,
                      "is-office-ip": _vm.isOfficeIp,
                    },
                    on: {
                      "dropdown:mounted": _vm.handleDropdownMounted,
                      "search-site:submit-form": function ($event) {
                        return _vm.$emit("search-site:submit-form")
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "input-control",
                        fn: function ({
                          inputValue,
                          placeholder,
                          handleBlur,
                          handleFocus,
                          handleInput,
                          handleKeydownArrowUp,
                          handleKeydownArrowDown,
                          handleSubmitForm,
                        }) {
                          return [
                            _c("VTextField", {
                              attrs: {
                                dense: "",
                                autofocus: "",
                                outlined: "",
                                "full-width": "",
                                "hide-details": "",
                                clearable: "",
                                "prepend-inner-icon":
                                  "b-button b-button_text ui-icon-arrow-back",
                                "clear-icon":
                                  "b-button b-button_text ui-icon-close-not-a-circle",
                                "append-icon":
                                  "b-button b-button_text ui-icon-search",
                                value: inputValue,
                                placeholder: placeholder,
                              },
                              on: {
                                blur: handleBlur,
                                focus: handleFocus,
                                input: handleInput,
                                keydown: [
                                  function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "up",
                                        38,
                                        $event.key,
                                        ["Up", "ArrowUp"]
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                    return handleKeydownArrowUp.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "down",
                                        40,
                                        $event.key,
                                        ["Down", "ArrowDown"]
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                    return handleKeydownArrowDown.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                ],
                                "click:prepend-inner": _vm.handleCloseModal,
                                "click:clear": handleInput,
                                "click:append": handleSubmitForm,
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }