<template>
  <div>
    <TheHeaderLine
      :site-logo="siteLogo"
      :search="search"
      data-qa="the_header_line"
      @header:click-search-btn="handleShowDialog"
    />

    <TheHeaderLine
      is-sticky
      :site-logo="siteLogo"
      :search="search"
      :is-visible-search-dialog="isVisibleSearchDialog"
      @header:click-search-btn="handleShowDialog"
    />

    <SearchDialog
      v-if="isElasticsearch"
      v-model="isVisibleSearchDialog"
      :town="search.location.town"
      :search-url="search.action"
      :location="searchLocation"
      :is-office-ip="isOfficeIp"
    />
    <TheHeaderSearchDialog
      v-else-if="search.useRender && isVisibleSearchDialog"
      :search="search"
      :is-office-ip="isOfficeIp"
      class="the-header__dialog"
      @search-dialog:hide="handleCloseDialog"
      @search-site:submit-form="handleSubmitForm"
    />
  </div>
</template>

<script>
import DialogManager from 'www/vue_components/common/mixins/DialogManager'
import TheHeaderLine from 'components/common/TheHeader/components/mobile/TheHeaderLine'
import TheHeaderSearchDialog from 'components/common/TheHeader/components/mobile/TheHeaderSearchDialog'
import { SITE_LOGO } from 'components/common/TheHeader/constants'
// eslint-disable-next-line import/extensions
import SearchDialog from 'components/common/SearchBar/components/SearchDialog.vue'

export default {
  name: 'TheHeader',
  components: {
    SearchDialog,
    TheHeaderLine,
    TheHeaderSearchDialog,
  },
  mixins: [
    DialogManager,
  ],
  props: {
    siteLogo: {
      type: Object,
      default: SITE_LOGO.pd,
    },
    search: {
      type: Object,
      default: () => ({}),
    },
    isOfficeIp: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isPendingSubmitForm: false,
    isVisibleSearchDialog: false,
    dialogName: 'search-dialog',
  }),
  computed: {
    isElasticsearch() {
      return window.FEATURE_FLAGS.pd_elasticsearch && this.search.useRenderElastic
    },
    isVisibleSearchDialogHistory() {
      return this.isTopDialog(this.dialogName)
    },
    searchLocation() {
      if (this.search.location.metro) {
        return {
          type: 'metro',
          name: this.search.location.metro,
        }
      }

      if (this.search.location.district) {
        return {
          type: 'district',
          name: this.search.location.district,
        }
      }

      return undefined
    },
  },
  watch: {
    isVisibleSearchDialogHistory(isVisible) {
      if (this.isElasticsearch) {
        return
      }

      if (!this.isPendingSubmitForm) {
        this.isVisibleSearchDialog = isVisible
      }
    },
  },
  methods: {
    handleShowDialog() {
      if (this.isElasticsearch) {
        this.isVisibleSearchDialog = true
        return
      }

      this.openDialog(this.dialogName)
    },
    handleCloseDialog() {
      this.closeDialog()
    },
    handleSubmitForm() {
      // isPendingSubmitForm - нужен для того, чтобы в момент отправки формы не закрывалась модалка с поиском
      // но при этом чтобы в history.state (closeDialog) отображалась запись о закрытии
      this.isPendingSubmitForm = true

      this.closeDialog()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.the-header {
  position: relative;
  padding-top: 64px;
  background-color: $ui-kit-bg-gray-0;

  &__dialog {
    position: fixed;
    inset: 0;
    z-index: 201;
    background-color: $ui-kit-bg-gray-0;
  }
}
</style>
