var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "VDialog",
    {
      attrs: { value: _vm.value, fullscreen: "" },
      on: {
        input: function ($event) {
          return _setup.emit("input", $event)
        },
      },
    },
    [
      _c(
        "VCard",
        [
          _c(
            "div",
            { staticClass: "px-2 pt-6 pb-2" },
            [
              _c(
                "VForm",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _setup.handleSubmitForm.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(_setup.VTextField, {
                    ref: "textField",
                    attrs: {
                      maxlength: _setup.MAX_QUERY_LENGTH,
                      type: "search",
                      "prepend-inner-icon": "ui-icon-arrow-back",
                      "clear-icon": "ui-icon-close-not-a-circle",
                      "append-outer-icon": "ui-icon-search",
                      outlined: "",
                      autofocus: "",
                      "full-width": "",
                      "hide-details": "",
                      clearable: "",
                    },
                    on: {
                      focus: _setup.handleFocusSearchInput,
                      "click:append-outer": _setup.handleSubmitForm,
                      "click:prepend-inner": function ($event) {
                        return _setup.emit("input", false)
                      },
                      "click:clear": function ($event) {
                        _setup.query = ""
                      },
                    },
                    model: {
                      value: _setup.query,
                      callback: function ($$v) {
                        _setup.query = $$v
                      },
                      expression: "query",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _setup.query?.length >= _setup.MIN_QUERY_LENGTH
            ? _c(_setup.SearchResults, {
                attrs: {
                  query: _setup.query,
                  town: _vm.town,
                  "is-office-ip": _vm.isOfficeIp,
                },
              })
            : _c(
                "div",
                {
                  staticClass:
                    "ui-text ui-text_body-1 ui-kit-color-text-secondary px-4 pt-8 pb-4 text-center",
                },
                [
                  _vm._v(
                    "\n      Вы можете искать по фамилии врача, названию клиники и услуге\n    "
                  ),
                ]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }