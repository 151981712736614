var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "VApp",
    { staticClass: "search-bar", attrs: { "data-search-bar": "" } },
    [
      _c(
        "form",
        {
          staticClass: "search-bar__container",
          style: { zIndex: _setup.isDialogVisible ? 5 : undefined },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _setup.handleSubmitForm.apply(null, arguments)
            },
          },
        },
        [
          _c(_setup.SearchInput, {
            ref: "input",
            staticClass: "search-bar__input",
            attrs: { placeholder: _vm.placeholder },
            on: {
              click: _setup.handleClickSearchInput,
              focus: _setup.handleFocusSearchInput,
              submit: _setup.handleSubmitForm,
            },
            nativeOn: {
              keydown: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "up", 38, $event.key, [
                      "Up",
                      "ArrowUp",
                    ])
                  )
                    return null
                  $event.preventDefault()
                  return _setup.handleKeydownArrowUp.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "down", 40, $event.key, [
                      "Down",
                      "ArrowDown",
                    ])
                  )
                    return null
                  $event.preventDefault()
                  return _setup.handleKeydownArrowDown.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape",
                    ])
                  )
                    return null
                  $event.preventDefault()
                  return _setup.handleKeydownEsc.apply(null, arguments)
                },
              ],
            },
            model: {
              value: _setup.query,
              callback: function ($$v) {
                _setup.query = $$v
              },
              expression: "query",
            },
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "search-bar__button b-button",
              class: {
                "ml-4": !_setup.isMobile,
                "b-button_appendable": _setup.isMobile,
              },
              attrs: {
                type: "submit",
                "data-role": "none",
                "aria-label": "Найти",
              },
            },
            [
              _c("span", {
                staticClass:
                  "ui-text ui-text_button ui-kit-color-bg-gray-0 ui-icon-search ui-icon_fz_big",
              }),
            ]
          ),
          _vm._v(" "),
          !_setup.isMobile &&
          _setup.isDialogVisible &&
          _setup.query.length >= _setup.MIN_QUERY_LENGTH
            ? _c(
                "div",
                { staticClass: "search-bar__dropdown-wrapper" },
                [
                  _c(
                    "VCard",
                    {
                      staticClass: "search-bar__dropdown",
                      attrs: { rounded: "" },
                    },
                    [
                      _c(_setup.SearchResults, {
                        ref: "searchResults",
                        attrs: {
                          query: _setup.query,
                          town: _vm.town,
                          "is-office-ip": _vm.isOfficeIp,
                        },
                        on: {
                          "activate-dropdown-item":
                            _setup.handleActivateDropdownItem,
                          "deactivate-dropdown-item":
                            _setup.handleDeactivateDropdownItem,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _setup.isMobile
        ? _c(_setup.SearchDialog, {
            attrs: {
              town: _vm.town,
              "search-url": _vm.searchUrl,
              location: _vm.location,
              "is-office-ip": _vm.isOfficeIp,
            },
            model: {
              value: _setup.isDialogVisible,
              callback: function ($$v) {
                _setup.isDialogVisible = $$v
              },
              expression: "isDialogVisible",
            },
          })
        : _c("VOverlay", {
            attrs: { "z-index": "1" },
            on: { click: _setup.handleClickOverlay },
            model: {
              value: _setup.isDialogVisible,
              callback: function ($$v) {
                _setup.isDialogVisible = $$v
              },
              expression: "isDialogVisible",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }