import { BLOCK_NAME } from 'modules/ExpansionPanels/constants'

function getNamespace() {
  return {
    block: BLOCK_NAME,
    panel: `${BLOCK_NAME}__panel`,
    panelActive: `${BLOCK_NAME}__panel_active`,
    head: `${BLOCK_NAME}__head`,
    body: `${BLOCK_NAME}__body`,
    button: `${BLOCK_NAME}__button`,
    buttonArrowPadding: `${BLOCK_NAME}__button_arrow-padding`,
    buttonHasArrow: `${BLOCK_NAME}__button_has-arrow`,
    buttonRightArrow: `${BLOCK_NAME}__button_has-arrow_right`,
  }
}

export default getNamespace
