// Маска используется как аналог регулярного выражения \d. Нужно для модуля vue-the-mask
const DIGIT_MASK = 'X'

/**
 * Эти маски используется, когда нужно использовать фиксированные первые цифры.
 * Алфавитный порядок в массиве не важен. Значения самих масок тоже: главное, чтобы это были одиночные символы.
 * Массив не должен содержать значение DIGIT_MASK
 * */
const FIRST_DIGITS_MASK_LIST = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']

const PHONE_DATA_BY_COUNTRY_ISO = {
  az: {
    bodyMasks: [
      'XX XXX-XX-X',
      'XX XXX-XX-XX',
    ],
  },
  am: {
    bodyMasks: [
      'XX XXX XXX',
    ],
  },
  by: {
    bodyMasks: [
      'XX XXX-XX-XX',
    ],
  },
  kz: {
    bodyMasks: [
      'XXX XXX-XX-XX',
    ],
  },
  kg: {
    bodyMasks: [
      'XXX XXX XXX',
    ],
  },
  tj: {
    bodyMasks: [
      'XX XXX XXXX',
    ],
  },
  uz: {
    bodyMasks: [
      'XX XXX-XX-XX',
    ],
  },
  ee: {
    bodyMasks: [
      'XXX XXXX',
      'XXXX XXXX',
    ],
  },
  ge: {
    bodyMasks: [
      'XXX XXX-XXX',
    ],
  },
  lt: {
    bodyMasks: [
      'XX XXXXXX',
    ],
  },
  lv: {
    bodyMasks: [
      'XXX XXXX',
      'XXX XXXXX',
    ],
  },
  md: {
    bodyMasks: [
      'XXX XXXXX',
    ],
  },
  ru: {
    bodyMasks: [
      'XXX XXX-XX-XX',
    ],
    firstDigits: [9], // 9 не должна выводиться, но пользователь может ввести только её
  },
}

export {
  DIGIT_MASK,
  FIRST_DIGITS_MASK_LIST,
  PHONE_DATA_BY_COUNTRY_ISO,
}
