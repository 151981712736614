<template>
  <form
    ref="root"
    class="search-site"
    data-qa="search_site_form"
    :class="{ 'search-site_focused': isFocused }"
    @submit.prevent="handleSubmitForm"
  >
    <div
      class="search-site__input-container"
      data-qa="search_site_input_container"
    >
      <slot
        name="input-control"
        v-bind="{
          isFocused,
          inputValue,
          placeholder,
          handleBlur,
          handleFocus,
          handleInput,
          handleKeydownArrowUp,
          handleKeydownArrowDown,
          handleSubmitForm,
        }"
      >
        <VTextField
          dense
          outlined
          full-width
          hide-details
          clearable
          clear-icon="ui-icon-close-not-a-circle"
          prepend-inner-icon="ui-icon-arrow-back"
          :value="inputValue"
          :placeholder="placeholder"
          @blur="handleBlur"
          @focus="handleFocus"
          @input="handleInput"
          @keydown.up.prevent="handleKeydownArrowUp"
          @keydown.down.prevent="handleKeydownArrowDown"
          @click:clear="handleInput"
        />
      </slot>

      <SearchSiteDropdown
        v-if="isShowMenu"
        :search-query="searchQueryForDropdown"
        :search-results="searchResults"
        :preview-text="previewText"
        :location="location"
        :trigger-keydown-input="triggerKeydownInput"
        data-qa="search_site_dropdown"
        @dropdown:selected-item="handleSelectedItem"
        @dropdown:mounted="handleDropdownMounted"
        @search-site:submit-form="handleSelectDropdownItem"
      />
    </div>

    <slot name="default" />
  </form>
</template>

<script>
import { axiosClient, debounce } from 'utils'
import YandexGoals from 'modules/YandexGoals'
import SearchSiteDropdown from 'components/common/SearchSite/components/SearchSiteDropdown'

const yaGoals = new YandexGoals()

export default {
  name: 'SearchSite',
  components: {
    SearchSiteDropdown,
  },
  props: {
    action: {
      type: String,
      default: '',
    },
    ajaxAction: {
      type: String,
      default: '',
    },
    location: {
      type: Object,
      default: () => ({
        town: '',
        district: '',
        microdistrict: '',
        street: '',
        metro: '',
      }),
    },
    placeholder: {
      type: String,
      default: '',
    },
    previewText: {
      type: String,
      default: '',
    },
    isOfficeIp: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    triggerKeydownInput: 0,
    isActiveSearch: false,
    isFocused: false,
    searchQuery: '',
    storedSearchQuery: '',
    searchResults: [],
    canceledRequestToken: () => {},
    selectedDropdownItem: {},
  }),
  computed: {
    inputValue() {
      return this.searchQuery || this.storedSearchQuery
    },
    searchQueryForDropdown() {
      return this.storedSearchQuery || this.searchQuery
    },
    isShowMenu() {
      return this.isActiveSearch && this.isContentForMenuExists
    },
    isContentForMenuExists() {
      return (this.searchResults.length && this.searchQuery) || this.previewText
    },
    accurateLocation() {
      // последовательность имеет значение
      const locationTypes = ['district', 'microdistrict', 'street', 'metro']

      // eslint-disable-next-line
            for (const locationType of locationTypes) {
        if (this.location[locationType]) {
          return {
            type: locationType,
            value: this.location[locationType],
          }
        }
      }

      return null
    },
  },
  created() {
    this.loadSearchResultsDebounced = debounce(this.loadSearchResults, 300)
  },
  mounted() {
    document.body.addEventListener('mousedown', this.handleClickOutside)
  },
  beforeDestroy() {
    document.body.removeEventListener('mousedown', this.handleClickOutside)
  },
  methods: {
    handleSelectDropdownItem() {
      if (!this.isOfficeIp) {
        yaGoals.send({ name: 'oldSearch_dropdownItem' })
      }

      this.$emit('search-site:submit-form')
    },
    hasGetParams(url) {
      return /\?\w+=/.test(url)
    },
    makeSearchUrl(url, query) {
      const querySeparator = this.hasGetParams(url) ? '&' : '?'

      return `${url}${querySeparator}q=${encodeURIComponent(query)}`
    },
    getRequestQueryString(query) {
      const queryArr = []

      queryArr.push(`q=${encodeURIComponent(query)}`)

      if (this.accurateLocation) {
        queryArr.push(`${this.accurateLocation.type}=${encodeURIComponent(this.accurateLocation.value)}`)
      }

      return `?${queryArr.join('&')}`
    },
    handleSubmitForm() {
      // Если пользователь выбрал опцию из выпадающего списка, то переходим по URL этой опции
      if (this.selectedDropdownItem.itemTitle && this.selectedDropdownItem.itemURL) {
        if (!this.isOfficeIp) {
          yaGoals.send({ name: 'oldSearch_dropdownItem' })
        }

        this.$emit('search-site:submit-form')

        window.location = this.selectedDropdownItem.itemURL

        return
      }

      // Иначе переходим по указанному запросу, если он существует
      if (this.searchQuery) {
        if (!this.isOfficeIp) {
          yaGoals.send({ name: 'oldSearch_searchButton' })
        }

        this.$emit('search-site:submit-form')

        window.location = `${this.action}${this.getRequestQueryString(this.searchQuery)}`
      }
    },
    handleClickOutside({ target }) {
      if (this.$refs.root.contains(target)) {
        return
      }

      this.isActiveSearch = false
    },
    handleFocus() {
      this.isActiveSearch = true
      this.isFocused = true

      if (!this.isOfficeIp) {
        yaGoals.send({ name: 'search_focus' })
      }
    },
    handleBlur() {
      this.isFocused = false

      if (this.storedSearchQuery) {
        this.searchQuery = this.storedSearchQuery
      }

      this.storedSearchQuery = ''
      this.selectedDropdownItem = {}
    },
    handleInput(eventValue) {
      this.searchQuery = typeof eventValue === 'string' ? eventValue : ''
      this.storedSearchQuery = ''
      this.selectedDropdownItem = {}
      this.loadSearchResultsDebounced(this.searchQuery)
    },
    loadSearchResults(queryValue) {
      this.canceledRequestToken()

      let isCanceledRequest = false

      this.canceledRequestToken = () => {
        isCanceledRequest = true
      }

      this.searchQuery = queryValue

      if (this.searchQuery.length) {
        axiosClient.get(this.makeSearchUrl(this.ajaxAction, this.searchQuery))
          .then(({ data }) => {
            // isCanceledRequest - необходим для того, чтобы при множестве
            // одновременных запросов всегда возвращать результат последнего
            if (isCanceledRequest) {
              return
            }

            this.searchResults = typeof data === 'string' ? JSON.parse(data) : data
          })
        // eslint-disable-next-line no-console
          .catch(({ message }) => console.error(message))
      }
    },
    handleKeydownArrowUp() {
      this.triggerKeydownInput += 1
    },
    handleKeydownArrowDown() {
      this.triggerKeydownInput -= 1
    },
    handleSelectedItem({ transformData }) {
      this.selectedDropdownItem = transformData

      if (transformData && !transformData.isItemShowMore) {
        if (!this.storedSearchQuery) {
          this.storedSearchQuery = this.searchQuery
        }

        this.searchQuery = transformData.itemTitle

        return
      }

      if (this.storedSearchQuery) {
        this.searchQuery = this.storedSearchQuery
      }

      this.storedSearchQuery = ''
    },
    handleDropdownMounted(dropdownElement) {
      this.$emit('dropdown:mounted', dropdownElement)
    },
  },
}
</script>
