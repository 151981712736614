<template>
  <SearchSite
    :action="action"
    :ajax-action="ajaxAction"
    :location="{
      town, district, microdistrict, street, metro,
    }"
    :placeholder="placeholder"
    :preview-text="prompt"
    class="base-search"
    :class="{ 'base-search_desktop': fullMode }"
    :is-office-ip="isOfficeIp"
  >
    <template
      #input-control="{
        isFocused,
        inputValue,
        handleBlur,
        handleFocus,
        handleInput,
        handleKeydownArrowUp,
        handleKeydownArrowDown,
      }"
    >
      <MaterialInput
        ref="inputComponent"
        class="base-search__input"
        type="text"
        autocomplete="off"
        :value="inputValue"
        :borders-has="fullMode ? '1 1 1 1' : '1 0 1 1'"
        :placeholder="placeholder"
        :gray="fullMode && !isFocused"
        required
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        @keydown.native.up.prevent="handleKeydownArrowUp"
        @keydown.native.down.prevent="handleKeydownArrowDown"
      />
    </template>

    <button
      type="submit"
      class="base-search__button b-button b-button_red b-button_big"
      :class="{
        'base-search__button_full': fullMode,
        'b-button_appendable': !fullMode,
      }"
      data-role="none"
      aria-label="Найти"
    >
      <span
        v-if="fullMode"
        class="ui-text ui-text_button ui-kit-color-bg-gray-0 ui-icon-search ui-icon_fz_big"
      />
      <svg
        v-else
        class="b-icon b-icon-search ui-text ui-kit-color-bg-gray-0"
        width="24"
        height="24"
      >
        <use xlink:href="/static/_v1/pd/svg-sprites/global.svg#icon-search" />
      </svg>
    </button>
  </SearchSite>
</template>

<script>
import SearchSite from 'components/common/SearchSite/components/SearchSite'
import MaterialInput from 'components/common/core/MaterialInput'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Search',
  components: {
    SearchSite,
    MaterialInput,
  },
  props: {
    town: {
      type: String,
      default: '',
    },
    ajaxAction: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    district: {
      type: String,
      default: '',
    },
    microdistrict: {
      type: String,
      default: '',
    },
    street: {
      type: String,
      default: '',
    },
    metro: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    prompt: {
      type: String,
      default: '',
    },
    fullMode: {
      type: Boolean,
      default: false,
    },
    isOfficeIp: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
@import '~www/themes/doctors/common/variables';

.base-search {
  display: flex;
  position: relative;

  &_desktop &__button {
    margin-left: 16px;
    flex: 0 0 56px;
  }

  &_desktop .search-site__input-container {
    position: relative;
  }

  .search-site__input-container {
    flex-grow: 1;
  }

  .search-site-dropdown {
    z-index: 2;
    padding: 24px 0;
    position: absolute;
    border-radius: 5px;
    background: $ui-kit-bg-gray-0;
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    box-sizing: border-box;
    top: 100%;
    font-size: $font-size-body-1;
    color: $color-neur-dark;
    user-select: none;

    @include elevation-6;

    &__item {
      padding: 0 24px;
    }

    &__preview-text {
      padding: 0 24px;
    }
  }
}
</style>
