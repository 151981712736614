import { dispatchCustomEventGlobally } from 'utils'
import { EVENTS as SNACKBAR_EVENTS } from 'components/common/core/SnackbarNotification/constants'

export default function waitingCreatedSnackbar(callback) {
  let isHandled = false

  window.addEventListener(SNACKBAR_EVENTS.created, () => {
    callback?.()
    isHandled = true
  }, { once: true })

  if (!isHandled) {
    dispatchCustomEventGlobally(SNACKBAR_EVENTS.checkCreated)
  }
}
