<template>
  <div
    class="review-get-user-email-success"
    :class="{ 'review-get-user-email-success_mobile': isMobile }"
    data-qa="review_get_user_email_success"
  >
    <div
      class="review-get-user-email-success__inner text-center"
    >
      <div :class="{ 'my-auto': isMobile }">
        <VImg
          contain
          width="200"
          height="200"
          class="flex-grow-0 mx-auto mb-4"
          data-qa="review_get_user_email_success_img"
          alt="Палец вверх"
          src="/static/_v1/pd/pictures/ui-kit/like.svg"
        />
        <div
          data-qa="review_get_user_email_success_title"
          class="ui-text ui-kit-color-text mb-2"
          :class="{
            'ui-text_h6': isMobile,
            'ui-text_h5': !isMobile,
          }"
        >
          <template v-if="commonData.isReviewSourcePrs">
            Принято. Сообщим о&nbsp;проверке<br>
            отзыва и&nbsp;пришлём промокод<br>
            на&nbsp;почту
          </template>
          <template v-else>
            Принято. Сообщим о <br>
            проверке отзыва по почте
          </template>
        </div>
        <div
          class="ui-text ui-text_body-1 ui-kit-color-text mb-4"
          data-qa="review_get_user_email_success_description"
        >
          {{ userData.email }}
        </div>
        <div
          v-if="!commonData.isPdMobile"
          class="review-get-user-email-success__banner ui-text ui-text_body-1 mb-4"
          data-qa="review_get_user_email_success_banner"
        >
          <p class="mb-2">
            Если вы не были в этой клинике
            и оставили отзыв по заказу клиники или
            врача, сообщите нам об этом и получите вознаграждение
          </p>
          <a
            v-if="!isYDoc"
            href="/info/rate-fraud-free/"
            target="_blank"
          >Подробнее</a>
        </div>
      </div>
      <!-- eslint-disable vue/no-template-target-blank -->
      <VBtn
        v-if="!isMobile"
        class="mb-4"
        depressed
        color="primary"
        target="_blank"
        data-qa="review_get_user_email_success_mtlink"
        :href="commonData.medtochkaUrlOnReview"
      >
        Посмотреть отзыв
      </VBtn>
      <!-- eslint-enable vue/no-template-target-blank -->
      <BottomPanel
        v-if="isMobile"
        fixed
        no-height
      >
        <template #container>
          <div class="px-2 pt-2 pb-6">
            <!-- eslint-disable vue/no-template-target-blank -->
            <VBtn
              block
              depressed
              color="primary"
              target="_blank"
              data-qa="review_get_user_email_success_mtlink"
              :href="commonData.medtochkaUrlOnReview"
            >
              Посмотреть отзыв
            </VBtn>
            <!-- eslint-enable vue/no-template-target-blank -->
          </div>
        </template>
      </BottomPanel>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import BottomPanel from 'components/common/core/BottomPanel/BottomPanel'
import { useUserDataStore, useReviewPageDataStore } from 'components/common/ReviewPage/stores'

export default {
  name: 'ReviewGetUserEmailSuccess',
  components: {
    BottomPanel,
  },
  data: () => ({
    isMobile: window.MOBILE_VERSION,
    isYDoc: window.IS_YDOC,
  }),
  computed: {
    ...mapState(useReviewPageDataStore, [
      'commonData',
    ]),
    ...mapState(useUserDataStore, [
      'userData',
    ]),
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.review-get-user-email-success {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &_mobile {
    min-height: 100%;
    padding: 8px 24px 72px;
  }

  &_mobile &__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &:not(&_mobile) {
    min-height: 664px;
  }

  &:not(&_mobile) &__inner {
    margin: 0 auto;
    max-width: 380px;
  }

  &__banner {
    padding: 16px;
    text-align: left;
    border-radius: 8px;
    background: linear-gradient(92deg, $ui-kit-brand, $ui-kit-primary);
    color: #FFFFFF;

    a {
      color: currentColor;
    }
  }
}
</style>
