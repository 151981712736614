/**
 * Возвращает слово в нужном падеже в зависимости от числа (1 единица, 2 единицы, 5 единиц).
 * @param number - число
 * @param wordForms - формы слова в нужных падежах (e.g. [единица, единицы, единиц])
 */
function getNounCase(number: number, wordForms: string[]) {
  const absNumber = Math.abs(number)
  const absHundredRemainder = absNumber % 100
  const tenRemainder = absHundredRemainder % 10
  const isFractional = absNumber % 1 !== 0

  if (isFractional) {
    return wordForms[2] // форма для дробных чисел
  }

  if (absHundredRemainder > 10 && absHundredRemainder < 20) {
    return wordForms[2] // форма для чисел от 11 до 19
  }

  if (tenRemainder > 1 && tenRemainder < 5) {
    return wordForms[1] // форма для чисел, оканчивающихся на 2, 3, 4
  }

  if (tenRemainder === 1) {
    return wordForms[0] // форма для чисел, оканчивающихся на 1
  }

  return wordForms[2] // форма для всех остальных чисел
}

export default getNounCase
