<template>
  <div class="authorization-form">
    <VForm @submit.prevent="onSubmitForm">
      <div class="authorization-form__field">
        <VTextField
          ref="phone-field-input"
          v-model="phoneField.value"
          v-mask="phoneField.theMask"
          :loading="phoneField.isLoading"
          :autofocus="isAutofocusField"
          :disabled="isPhoneDisabled"
          :error-messages="errorMessage"
          :hide-details="phoneField.isConfirmed"
          data-qa="user_authorization_phone_input"
          outlined
          type="tel"
          label="Номер телефона"
          @input="handlePhoneInput"
          @focus="handlePhoneFocus"
          @blur="handlePhoneBlur"
        >
          <template
            v-if="phoneField.isConfirmed"
            #append
          >
            <div class="ui-icon-check ui-kit-color-success" />
          </template>
        </VTextField>
        <div
          v-if="isVisiblePen"
          class="authorization-form__pen ui-icon-pen"
          data-qa="user_authorization_change_phone"
          @click="handleClickEditPhone"
        />
      </div>
    </VForm>
    <VExpandTransition>
      <div
        v-show="isVisibleDefaultSlot || isAuthorizationVk && isDefaultSlotAlwaysVisible"
        :class="{
          'authorization-form__default-slot': true,
          'authorization-form__default-slot_disabled': phoneField.isLoading,
        }"
      >
        <slot
          name="default"
          v-bind="{
            isActiveSlot: isVisibleDefaultSlot && !phoneField.isLoading,
            isCodeInputVisible: isVisibleDefaultSlot || !isAuthorizationVk,
          }"
        />
      </div>
    </VExpandTransition>
    <slot
      v-if="isVisibleActionSlot"
      name="action"
      v-bind="{
        onSubmitForm,
        loading: phoneField.isLoading,
        disabled: isDisabledAction,
        isVisible: !isVisibleDefaultSlot,
      }"
    />
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import { getOnlyNumbers } from 'utils'
import CountryData from 'components/common/mixins/CountryData'
import { PHONE_MASK_OPTIONS, PHONE_PREFIX, PHONE_RULES } from 'components/common/UserAuthorization/constants'
import { getPhoneValidationRules } from 'components/common/UserAuthorization/functions'

export default {
  name: 'UserAuthorizationForm',
  directives: {
    mask,
  },
  mixins: [
    CountryData,
  ],
  props: {
    isPhoneConfirmed: {
      type: Boolean,
      default: false,
    },
    isPhoneReadonly: {
      type: Boolean,
      default: false,
    },
    isNeededConfirmByCall: {
      type: Boolean,
      default: false,
    },
    isAutofocusField: {
      type: Boolean,
      default: false,
    },
    isAllowedSubmit: {
      type: Boolean,
      default: false,
    },
    isBlockingError: {
      type: Boolean,
      default: false,
    },
    isDefaultSlotAlwaysVisible: {
      type: Boolean,
      default: false,
    },
    initPhone: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    hookBeforeSubmitForm: {
      type: Function,
      default: async () => true,
    },
    fnHandlerSubmitForm: {
      type: Function,
      default: null,
    },
    isCountryAuthorization: {
      type: Boolean,
      default: false,
    },
    isAuthorizationVk: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phoneField: {
        value: this.initPhone, // 79999999999
        isLoading: false,
        isReadonly: false,
        isConfirmed: false,
        isInEditState: false,
        rules: PHONE_RULES,
        prefix: PHONE_PREFIX,
        theMask: PHONE_MASK_OPTIONS,
      },
      tempPhoneField: {},
    }
  },
  computed: {
    isVisiblePen() {
      if (this.isPhoneReadonly) {
        return false
      }

      if (this.isNeededConfirmByCall && !!this.initPhone) {
        return false
      }

      return (this.phoneField.isReadonly && !this.phoneField.isConfirmed)
        || this.isBlockingError
    },
    isPhoneDisabled() {
      return this.phoneField.isConfirmed
        || this.phoneField.isReadonly
        || this.phoneField.isLoading
        || this.isBlockingError
        || (this.isNeededConfirmByCall && !!this.initPhone)
        || this.isPhoneReadonly
    },
    isDisabledAction() {
      return this.phoneField.isLoading
        || !this.isValidPhone
        || this.isBlockingError
    },
    isVisibleDefaultSlot() {
      return this.phoneField.isReadonly
        && !this.phoneField.isConfirmed
        && !this.isBlockingError
    },
    isVisibleActionSlot() {
      return !this.phoneField.isConfirmed
    },
    isChangedPhone() {
      return this.phoneField.value !== this.tempPhoneField.value
    },
    isValidPhone() {
      return this.phoneField.rules.every(validation => validation(this.phoneField.value) === true)
    },
    isChangedAndValidPhone() {
      return this.isChangedPhone && this.isValidPhone
    },
  },
  watch: {
    isPhoneConfirmed: {
      immediate: true,
      handler(isConfirmed) {
        this.phoneField.isConfirmed = isConfirmed
      },
    },
  },
  created() {
    if (this.isCountryAuthorization && this.country?.phone) {
      this.phoneField.rules = getPhoneValidationRules(this.country.phone)
      this.phoneField.prefix = `${this.country.phone.code} `
      this.phoneField.theMask = this.country.phone.maskOptions
    }
  },
  methods: {
    async handleClickEditPhone() {
      this.$emit('form-authorization:click-edit')

      this.saveStatePhoneField()

      this.phoneField.isReadonly = false
      this.phoneField.isInEditState = true

      /* Дожидаемся когда произойдёт активация поля,
       * чтобы в нём можно было сфокусироваться. */
      await this.$nextTick()

      this.$refs['phone-field-input'].focus()
    },
    async updatePrefixPhone() {
      /* Дожидаемся когда изменится поле текущим событием `input`,
            * чтобы можно было внести своё изменение в это же поле. */
      await this.$nextTick()

      if (this.phoneField.value.startsWith(this.phoneField.prefix)) {
        return
      }

      this.phoneField.value = this.phoneField.prefix
    },
    setPhonePrefix(hasPrefix) {
      if (hasPrefix) {
        if (!this.phoneField.value) {
          this.phoneField.value = this.phoneField.prefix
        }

        return
      }

      if (!this.isValidPhone) {
        this.phoneField.value = ''
      }
    },
    handlePhoneInput() {
      this.updatePrefixPhone()
      this.$emit('form-authorization:input', this.isChangedAndValidPhone)
    },
    handlePhoneFocus() {
      this.setPhonePrefix(true)
    },
    saveStatePhoneField() {
      this.tempPhoneField = JSON.parse(JSON.stringify(this.phoneField))

      delete this.tempPhoneField.rules
      delete this.tempPhoneField.theMask
    },
    restoreStatePhoneField() {
      this.phoneField = { ...this.phoneField, ...this.tempPhoneField }
      this.tempPhoneField = {}
    },
    handlePhoneBlur() {
      this.setPhonePrefix(false)

      // Если нажали на `редактировать телефон` перед событием `blur`
      if (this.phoneField.isInEditState) {
        // Говорим родительскому компоненту - изменился ли телефон после редактирования
        this.$emit('form-authorization:input', this.isChangedAndValidPhone)

        // Если поле не изменилось или оно не валидно
        if (!this.isChangedAndValidPhone) {
          // Возвращаем состояние поля до нажатия на `редактировать телефон`
          this.restoreStatePhoneField()
        }
      }
    },
    resetEditState() {
      /* Если пользователь отправил форму через `enter`, то сработает `blur` после успешного
          запроса (т.к. поле с телефоном станет disabled), что приведёт к некорректному вызову `emmit` в `blur`,
          и некорректному повторному вызову `blur`. */
      this.phoneField.isInEditState = false
      this.$emit('form-authorization:input', false)
    },
    async onSubmitForm() {
      const isCanContinue = await this.hookBeforeSubmitForm()

      if (this.isDisabledAction || !this.isAllowedSubmit || !isCanContinue) {
        return
      }

      this.resetEditState()

      try {
        this.phoneField.isLoading = true

        await this.fnHandlerSubmitForm({
          phone: getOnlyNumbers(this.phoneField.value),
        })

        this.phoneField.isLoading = false
        this.phoneField.isReadonly = true
      } catch {
        this.phoneField.isLoading = false
        this.phoneField.isReadonly = false
      }
    },
    /**
     * Публичный метод, используется для сброса состояния компонента.
     * */
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this))

      if (this.isCountryAuthorization && this.country?.phone) {
        this.phoneField.rules = getPhoneValidationRules(this.country.phone)
        this.phoneField.prefix = `${this.country.phone.code} `
        this.phoneField.theMask = this.country.phone.maskOptions
      }

      // Генерируем событие `input` чтобы обновились состояния внешних компонентов после сброса текущего компонента
      this.$refs['phone-field-input'].$refs.input.dispatchEvent(new Event('input'))
    },
    /**
     * Публичный метод, используется для сброса состояния компонента.
     * */
    resetWithoutPhone() {
      const phone = this.phoneField.value

      this.reset()

      this.phoneField.value = phone
    },
  },
}
</script>

<style lang="scss" scoped>
.authorization-form {
  &__field {
    position: relative;
  }

  &__pen {
    position: absolute;
    z-index: 1;
    top: 17px;
    right: 12px;
    cursor: pointer;
    color: rgba(#000000, 0.54);
  }

  &__default-slot {
    transition: opacity 0.25s ease-in;

    &_disabled {
      opacity: 0.25;
      pointer-events: none;
    }
  }
}
</style>
